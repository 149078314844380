import { useQuery } from 'react-query'
import { action, observable } from 'mobx'
import API from '../common/api'
import {
  PlaylistDmcaValidationRequest,
  PlaylistDmcaValidationResponse,
  PlaylistsResponse,
  MonetizationTypesResponse,
} from '../common/api/playlist/playlist'
import PaginationStore from '../common/PaginationStore'

import { StoreNames } from '../common/constants'

class PlaylistsStore extends PaginationStore {
  storeName: string = StoreNames.PLAYLISTS_STORE

  @observable
  name: string = ''
  @observable
  appName: string = ''
  @observable
  published: boolean | null = null
  @observable
  type: string = ''

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleAppSearch = (filter: string) => {
    this.appName = filter?.trim()
    this.changePage()
  }

  @action
  handlePublishSearch = (filter: boolean | null) => {
    this.published = filter
    this.changePage()
  }

  @action
  handleTypeSearch = (filter: string) => {
    this.type = filter
    this.changePage()
  }
}

export const useListPlaylistsQuery = (filterStore: PlaylistsStore) => {
  const { pageSize, page, name, appName, published, type } = filterStore

  return useQuery<PlaylistsResponse>(
    ['list-playlists', pageSize, page, name, appName, published, type],
    () => {
      return API.playlist.list({
        page: page,
        size: pageSize,
        name,
        appName,
        published: published,
        type: type,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const usePlaylistDmcaQuery = (playlistId: string) => {
  return useQuery<PlaylistDmcaValidationResponse, PlaylistDmcaValidationRequest>(
    [`${playlistId}-playlist-dmca`, playlistId],
    () => {
      return API.playlist.playlistsDmcaCheck({ playlistId: playlistId })
    },
    {
      retry: 1,
      keepPreviousData: false,
    },
  )
}

export const usePlaylistBasicInfoQuery = (playlistId: string) => {
  return useQuery(
    [`playlist-${playlistId}-basic-info`],
    () => {
      return API.playlist.getBasicInfo({ playlistId: playlistId })
    },
    {
      retry: 1,
      keepPreviousData: false,
    },
  )
}

export const useMonetizationTypesQuery = () => {
  return useQuery<MonetizationTypesResponse>(
    ['monetizationTypes'],
    () => {
      return API.playlist.getMonetizationTypes()
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useTiersQuery = () => {
  return useQuery<any>(
    ['tiers'],
    () => {
      return API.playlist.getPlaylistTiers()
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export default PlaylistsStore
