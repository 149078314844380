import useStores from 'common/hook/useStore'
import { useCreateImageMutation } from 'common/hook/createImage'
import { MAX_SIZE_IN_BYTES, MAX_SIZE_IN_KB } from 'common/constants'
import { ImageEntity } from 'common/api/image/image'

import { Tooltip } from '@mui/material'
import Button from 'ui/Button/Button'

import Text from 'locale/strings'

import InfoIcon from '@mui/icons-material/Info'
import styles from './ImageUploadField.module.scss'

interface ImageUploadFieldProp {
  image: string
  hangleSetImage: (imageId: string) => void
}

const ImageUploadField = ({ image, hangleSetImage }: ImageUploadFieldProp) => {
  const { notificationsStore } = useStores()

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    hangleSetImage(data.id)
  })

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null

    if (!file) {
      return
    }

    if (file.size > MAX_SIZE_IN_BYTES) {
      notificationsStore.errorNotificationText(`File size must not exceed ${MAX_SIZE_IN_KB}KB`)

      return false
    } else if (file.type.includes('image/')) {
      const formData = new FormData()

      formData.append('filename', file.name)
      formData.append('file', file)

      mutationImage.mutate(formData)
    }
  }

  return (
    <div className={styles.imageWrapper}>
      <img src={image} alt="currencyImage" />
      <label htmlFor="file-image-id">
        <input
          hidden
          type="file"
          id="file-image-id"
          accept=".png, .jpg, .jpeg, .ico, .bmp"
          value=""
          onChange={handleImageUpload}
        />
        <div className={styles.uploadButtonWrapper}>
          <Button isUpload data-test="upload-image-button">
            {Text.page.styngs.create.uploadCover}
          </Button>
          <Tooltip title="Supported formats: PNG, JPG, JPEG, ICO and BPM">
            <InfoIcon color="action" sx={{ marginLeft: 1, cursor: 'pointer' }} />
          </Tooltip>
        </div>
      </label>
    </div>
  )
}

export default ImageUploadField
