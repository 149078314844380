import React, { useState } from 'react'
import { JsonValidationIcon } from 'ui/Table/Row/images'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import JsonDataModalWithApplication from 'ui/JsonDataModal/JsonDataModalWithApplication/JsonDataModalWithApplication'
import DeleteModal from '../../ui/DeleteModal/DeleteModal'

import { Ad } from '../../common/api/radioAdGroups/radioAdGroups'
import { FileType } from '../../common/constants'

import styles from './adItem.module.scss'

interface AdItemProps {
  playlistBasicInfoData: any
  ad: Ad
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  refetch?: any
}

const AdItem = ({ playlistBasicInfoData, ad, onEdit, onDelete, refetch }: AdItemProps) => {
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleEditClick = () => {
    onEdit(ad.basicInfo.id)
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleDelete = () => {
    onDelete(ad.basicInfo.id)
  }

  const handleOpenJsonModal = () => {
    setJsonDataModal(true)
  }

  const handleCloseJsonModal = () => {
    setJsonDataModal(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{ad.basicInfo.name}</h3>
      </div>
      <EditIcon className={styles.deleteIcon} color={'action'} onClick={handleEditClick} />
      <DeleteIcon className={styles.deleteIcon} color={'action'} onClick={handleDeleteClick} />
      {ad.metadata && (
        <div className={styles.svgIcon} onClick={() => handleOpenJsonModal()}>
          <JsonValidationIcon rule={Object.keys(ad.metadata).length !== 0} />
        </div>
      )}

      {jsonDataModal && (
        <JsonDataModalWithApplication
          platformId={playlistBasicInfoData.platformId}
          dataTypeId={ad.basicInfo?.id}
          data={ad?.metadata}
          dataType={FileType.AD}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseJsonModal}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={'Remove ad from ad group'}
          handleClose={handleCloseDeleteModal}
          text={'Are you sure you want to permanently delete this ad?'}
          handleSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default React.memo(AdItem)
