import * as R from 'ramda'
import { Duration } from 'luxon'
import axios from 'axios'

import { AdminMode, TEXT_TRUNCATE_SIZE } from './constants'

export const setDefaultRequestHeader = (key: string, value: Nullable<string>) => {
  axios.defaults.headers.common[key] = value
}

export const formatAuthorizationHeader = (accessToken: string) => `Bearer ${accessToken}`

export const installResponseInterceptor = (onSuccess: any, onFailure: any) => {
  axios.interceptors.response.use(onSuccess, onFailure)
}

export const is401Reseponse = R.propEq('status', 401)

export const getAdminMode = (value: Nullable<string>): Nullable<AdminMode> => {
  switch (value) {
    case AdminMode.ON:
      return AdminMode.ON
    case AdminMode.OFF:
      return AdminMode.OFF
  }

  return null
}

export const emptyToNull = (value: string) => (R.isEmpty(value) ? null : value)

export const toIdArray = R.map(R.prop('id'))

export const formatItemsForCsvData = (item: any) => {
  if (item === null) {
    return
  }

  const itemToFormat = item.toString().replace(/[^\w\s]/gi, '')

  if (itemToFormat.includes(',') || itemToFormat.includes('-')) {
    return itemToFormat.replace(/,/g, '|')
  }

  return itemToFormat
}

export const converDurationToNanoseconds = (trackDuration: any) => {
  return Duration.fromISO(trackDuration).as('seconds') * 1e9
}

export const truncateText = (text: string | number = '', size = TEXT_TRUNCATE_SIZE) => {
  const textFormatted = text.toString()

  return textFormatted.length > size ? textFormatted.slice(0, size - 1) + '…' : textFormatted
}

export const handleTypeNumber = (event: React.KeyboardEvent) => {
  const char = event.key

  if (!/[0-9.]/.test(char) && char !== 'Backspace') {
    event.preventDefault()
  }
}

export const isMetadataSetForCurrentPlatform = (externalMetadata: any, platformId = '') => {
  const metadata = externalMetadata[platformId]

  if (typeof metadata !== 'undefined') {
    const formatMetadataToObject = JSON.parse(metadata)
    const isMetadataSet = Object.keys(formatMetadataToObject).length === 0
    const dataForIconColor = isMetadataSet ? 'NULL' : ''

    return dataForIconColor
  }

  return 'NULL'
}

export const isMetadataSet = (externalMetadata: any) => {
  for (const key in externalMetadata) {
    const metadata = externalMetadata[key]

    if (typeof metadata !== 'undefined') {
      const formatMetadataToObject = JSON.parse(metadata)
      const isMetadataSet = Object.keys(formatMetadataToObject).length === 0

      if (!isMetadataSet) {
        return ''
      }
    }
  }

  return 'NULL'
}
