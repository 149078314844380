import PlaylistsPage from './pages/PlaylistsPage'
import strings from 'locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'
import PlaylistEditPage from './pages/PlaylistEditPage'
import CreatePlaylist from './pages/CreatePlaylist/CreatePlaylist'

export const PlaylistsRoute: Route = {
  path: '/playlists',
  name: strings.navigation.playlists,
  exact: true,
  component: PlaylistsPage,
}

export const PlaylistsCreateRoute: Route = {
  path: '/playlists/create',
  name: strings.navigation.createPlaylist,
  exact: true,
  component: CreatePlaylist,
}

export const PlaylistEditRoute: ParametrizedRoute = {
  path: '/playlists/edit/:playlistId',
  name: strings.navigation.playlist,
  exact: true,
  component: PlaylistEditPage,
  getUrl: ({ playlistId }) => `/playlists/edit/${playlistId}`,
}
