import strings from 'locale/strings'
import { ParametrizedRoute } from 'lib/routing/Route'
import RadioAd from 'radioAds/pages/RadioAd/RadioAd'

export const AdCreateRoute: ParametrizedRoute = {
  path: '/ad/create/:playlistId/:adGroupId',
  name: strings.navigation.createAd,
  exact: true,
  component: RadioAd,
  getUrl: ({ playlistId, adGroupId }) => `/ad/create/${playlistId}/${adGroupId}`,
}

export const AdEditRoute: ParametrizedRoute = {
  path: '/ad/edit/:playlistId/:adGroupId/:id',
  name: strings.navigation.editAd,
  exact: true,
  component: RadioAd,
  getUrl: ({ playlistId, adGroupId, id }) => `/ad/edit/${playlistId}/${adGroupId}/${id}`,
}
