/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import * as yup from 'yup'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'

import useStores from '../../../common/hook/useStore'
import { PlaylistsRoute } from '../../index'

import Text from '../../../locale/strings'

import {
  Toolbar,
  Typography,
  FormControl,
  Autocomplete,
  Checkbox,
  TextField as MuiTextField,
  Box,
  Chip,
} from '@mui/material'

import Button, { ButtonColor, ButtonVariant } from '../../../ui/Button/Button'
import Select from '../../../ui/Select/Select'
import TextField from '../../../ui/TextField/TextField'
import LoadingComponent from '../../../ui/Loading/LoadingPage'
import ErrorLoading from '../../../ui/ErrorLoading/ErrorLoading'

import { OptionType } from '../../../common/api/common/common'
import { Platform } from 'common/api/platforms/platforms'
import API from '../../../common/api'
import { PlaylistTypes, ApiStatuses, PlaylistTiers } from '../../../common/constants'
import { EditPlaylistRequest, MakeEmptyPlaylistRequest } from 'common/api/playlist/playlist'

import { usePlatformListQuery, usePlatformAppsListQuery } from 'platforms/PlatformsStore'
import { useMonetizationTypesQuery, useTiersQuery } from '../../PlaylistsStore'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import styles from './CreatePlaylist.module.scss'
import { Application } from 'common/api/app/app'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const FILE_UPLOAD_TIME = 2500

const typeOptions = [
  { label: 'Licensed music', value: PlaylistTypes.LICENSED },
  { label: 'Royalty-free music', value: PlaylistTypes.ROYALTY_FREE },
]

const schema = yup.object({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(70, 'Name must be at most 70 characters'),
  platformId: yup.string().required('Platform is required'),
  type: yup.string().required('Playlist type is required'),
  monetizationType: yup.string().required('Monetization type is required'),
})

interface PlaylistFormValues {
  name: string
  platformId: string
  appIds: any[]
  type: string
  monetizationType: string
  tiers: string[]
}

const initialValues: PlaylistFormValues = {
  name: '',
  platformId: '',
  appIds: [],
  type: typeOptions[0].value,
  monetizationType: '',
  tiers: [],
}

const toPlatformsOptions = (item: Platform): OptionType => ({
  label: item.name,
  value: item.id,
})

const toMonetizationTypesOptions = (item: any): OptionType => ({
  label: item.description,
  value: item.type,
})

const CreatePlaylist = () => {
  const { navigationStore, notificationsStore, platformsStore } = useStores()
  const [playlist, setPlaylist] = useState<Nullable<File>>()
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [platformId, setPlatformId] = useState<string>('')

  const { data: platformsData } = usePlatformListQuery(platformsStore)

  const { data: platformAppsData, refetch: refetchApps } = usePlatformAppsListQuery(platformId)

  const [platformsList, setPlatformsList] = useState<OptionType[]>([])

  const [selectedApps, setSelectedApps] = useState<Application[]>([])
  const [allApps, setAllApps] = useState<Application[]>([])

  const { data: tiersData } = useTiersQuery()

  const [selectedTiers, setSelectedTiers] = useState<any[]>([])
  const [allTiers, setAllTiers] = useState<any[]>([])

  useEffect(() => {
    setPlatformsList(platformsData?.items.map(toPlatformsOptions) ?? [])
  }, [platformsData])

  useEffect(() => {
    if (platformId) {
      refetchApps()
    }
  }, [platformId])

  useEffect(() => {
    if (typeof platformAppsData !== undefined) {
      setAllApps(platformAppsData?.applications ?? [])
    }
  }, [platformAppsData])

  useEffect(() => {
    setAllTiers(tiersData?.tiers ?? [])
  }, [tiersData])

  const { data: monetizationTypesData, status: monetizationTypesSatus } = useMonetizationTypesQuery()

  const monetizationTypes = monetizationTypesData?.monetizationTypes ?? []

  const monetizationTypesOptions: OptionType[] = monetizationTypes.map(toMonetizationTypesOptions)

  const addPlaylistMutation = useMutation<null, Error, EditPlaylistRequest>(
    (body: EditPlaylistRequest) => {
      return API.playlist.create(body)
    },
    {
      onSuccess: () => {
        navigationStore.goToPage(PlaylistsRoute.path)

        notificationsStore.successNotification('Playlist successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const createEmptyPlaylistMutation = useMutation<null, Error, MakeEmptyPlaylistRequest>(
    (body: MakeEmptyPlaylistRequest) => {
      return API.playlist.create(body)
    },
    {
      onSuccess: () => {
        navigationStore.goToPage(PlaylistsRoute.path)

        notificationsStore.successNotification('Playlist successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleSubmit = async (values: PlaylistFormValues) => {
    let uploadId: string
    let uploadUrl: string

    if (values.name === '') {
      return
    }

    if (playlist === undefined) {
      const data: any = {
        name: values.name.trim(),
        platformId: values.platformId,
        appIds: selectedApps.map((item) => item.id),
        type: values.type,
        monetizationType: values.monetizationType,
        fileId: '',
        tiers: selectedTiers.map((item) => item.id),
      }

      createEmptyPlaylistMutation.mutate(data)
    }

    if (playlist) {
      const getDataForPlaylistUpload = API.playlist.playlistUploadData()

      getDataForPlaylistUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: playlist,
          })
        })
        .then(() => {
          const data = {
            name: values.name.trim(),
            platformId: values.platformId,
            appIds: selectedApps.map((item) => item.id),
            type: values.type,
            monetizationType: values.monetizationType,
            fileId: uploadId,
            tiers: selectedTiers.map((item) => item.id),
          }

          addPlaylistMutation.mutate(data)
        })
    }
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (!file.name.includes('csv')) {
        setShowInfo(true)

        setTimeout(() => {
          setShowInfo(false)
        }, FILE_UPLOAD_TIME)
      } else {
        setPlaylist(file)
      }
    }
  }

  const formik = useFormik<PlaylistFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue } = formik

  const handlePlatformChange = (platform: string) => {
    setFieldValue('platformId', platform)
    setPlatformId(platform)
  }

  const handleAppChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedApps(value)
  }

  const handleDeleteApp = (value: any) => {
    setSelectedApps((countryArray) => countryArray.filter((appOption) => appOption.id !== value))
  }

  const handleTypeChange = (typeOption: string) => {
    setFieldValue('type', typeOption)
  }

  const handleMonetizationTypeChange = (monetizationTypeOption: string) => {
    setFieldValue('monetizationType', monetizationTypeOption)
  }

  const handleTierChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedTiers(value)
  }

  const handleDeleteTier = (value: any) => {
    setSelectedTiers((countryArray) => countryArray.filter((appOption) => appOption.id !== value))
  }

  return (
    <Choose>
      <When condition={monetizationTypesSatus === ApiStatuses.LOADING}>
        <LoadingComponent />
      </When>
      <When condition={monetizationTypesSatus === ApiStatuses.SUCCESS}>
        <Toolbar className={styles.toolbar}>
          <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
            Create playlist
          </Typography>
        </Toolbar>
        <div className={styles.container}>
          <div className={styles.form}>
            <TextField
              fullWidth
              data-test="name-field"
              className={styles.textField}
              label={Text.page.playlists.create.fields.name + ' *'}
              name="name"
              value={values.name}
              error={formik.errors.name}
              onChange={formik.handleChange}
            />
            <FormControl fullWidth>
              <Select
                fullWidth
                data-test="name-platform"
                label={'Platform *'}
                value={values.platformId}
                options={platformsList}
                className={styles.select}
                error={formik.errors.platformId}
                onChange={handlePlatformChange}
              />
            </FormControl>

            {platformId === '' || allApps.length < 1 ? (
              <TextField
                fullWidth
                disabled
                className={styles.textField}
                label={'Applications *'}
                value={'Choose platform that have at least one application'}
              />
            ) : (
              <div className={styles.autocompleteWrapper}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  data-test="applications-field"
                  id="applications"
                  className={styles.autocompleteField}
                  value={selectedApps}
                  options={allApps}
                  getOptionLabel={(option: any) => option.name}
                  renderTags={() => null}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MuiTextField {...params} label={'Applications *'} placeholder="Search" />}
                  onChange={handleAppChange}
                />
                <Box className={styles.autocompleteFieldValues}>
                  {selectedApps.map((option) => (
                    <Chip
                      className={styles.autocompleteFieldValue}
                      key={option.id}
                      label={option.name}
                      onDelete={() => handleDeleteApp(option.id)}
                    />
                  ))}
                </Box>
              </div>
            )}
            <FormControl fullWidth>
              <Select
                fullWidth
                data-test="playlist-type-application"
                label={'Playlist type'}
                value={values.type}
                options={typeOptions}
                className={styles.select}
                error={formik.errors.type}
                onChange={handleTypeChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <Select
                fullWidth
                data-test="monetization-type-application"
                label={'Monetization type  *'}
                value={values.monetizationType}
                options={monetizationTypesOptions}
                className={styles.select}
                error={formik.errors.monetizationType}
                onChange={handleMonetizationTypeChange}
              />
            </FormControl>
            <div className={styles.autocompleteWrapper}>
              <Autocomplete
                disabled={values.monetizationType !== PlaylistTiers.PREMIUM}
                multiple
                disableCloseOnSelect
                data-test="create-playlist-tiers-field"
                id="tiers"
                className={styles.autocompleteField}
                value={selectedTiers}
                options={allTiers}
                getOptionLabel={(option: any) => option.name}
                renderTags={() => null}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <MuiTextField {...params} label={'Tiers'} placeholder="Search" />}
                onChange={handleTierChange}
              />
              <Box className={styles.autocompleteFieldValues}>
                {selectedTiers.map((option) => (
                  <Chip
                    className={styles.autocompleteFieldValue}
                    key={option.id}
                    label={option.name}
                    onDelete={() => handleDeleteTier(option.id)}
                  />
                ))}
              </Box>
            </div>
            <div className={styles.uploadCover}>
              <label htmlFor="file-input-id">
                <input hidden id="file-input-id" type="file" accept="text/csv" value="" onChange={handleFileUpload} />
                <Button data-test="upload-button" isUpload color={ButtonColor.INFO}>
                  {Text.page.playlists.create.uploadPlaylist}
                </Button>
              </label>
              {!R.isNil(playlist) && <span className={styles.fileName}>{playlist?.name}</span>}
            </div>
            {showInfo && <div className={styles.info}>Only .csv file is valid</div>}
            <div className={styles.submitContainer}>
              <Button
                data-test="cancel-button"
                variant={ButtonVariant.OUTLINED}
                onClick={() => navigationStore.goToPage(PlaylistsRoute.path)}
              >
                {Text.common.cancel}
              </Button>
              <Button
                data-test="submit-button"
                color={ButtonColor.PRIMARY}
                loading={addPlaylistMutation.isLoading}
                onClick={formik.handleSubmit}
              >
                {Text.common.save}
              </Button>
            </div>
          </div>
        </div>
      </When>
      <When condition={monetizationTypesSatus === ApiStatuses.ERROR}>
        <ErrorLoading />
      </When>
    </Choose>
  )
}

export default observer(CreatePlaylist)
