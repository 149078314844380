import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import useStores from '../../../common/hook/useStore'
import { usePlatformAppsListQuery } from 'platforms/PlatformsStore'

import Modal from '@mui/material/Modal'
import JsonDataModalRows from './../JsonDataModalRows/JsonDataModalRows'

import Text from '../../../locale/strings'
import Select from 'ui/Select/Select'
import Button, { ButtonVariant } from '../../Button/Button'

import API from 'common/api'
import { FileType } from 'common/constants'
import { OptionType } from 'common/api/common/common'

import { UpdateAdMetadataRequest } from 'common/api/radioAds/radioAds'

import styles from './JsonDataModalWithApplication.module.scss'

interface JsonDataModalWithApplicationProps {
  open: boolean
  dataTypeId: string | undefined
  data: any
  dataType: string
  platformId: string
  refetch: () => void
  handleClose: () => void
}

const toApplicationOptions = (item: any): OptionType => ({
  label: item.name,
  value: item.id,
})

const JsonDataModalWithApplication = ({
  open,
  platformId,
  dataTypeId,
  data,
  dataType,
  refetch,
  handleClose,
}: JsonDataModalWithApplicationProps) => {
  const { notificationsStore } = useStores()
  const [appMetadataObject, setAppMetadataObject] = useState<any>([])
  const [appMetadataArray, setAppMetadataArray] = useState<any>([])
  const [showErrorOnSave, setShowErrorOnSave] = useState<boolean>(false)

  const { data: appData } = usePlatformAppsListQuery(platformId)
  const appTypes = appData?.applications ?? []
  const applicationOptions: OptionType[] = appTypes.map(toApplicationOptions)

  const [applicationId, setApplicationId] = useState<string>('')

  useEffect(() => {
    const appFormattedObj = { ...data }

    if (Object.keys(appFormattedObj).length !== 0) {
      for (const key in appFormattedObj) {
        try {
          const parsedValue = JSON.parse(appFormattedObj[key])
          const helperMetadataArray = []

          for (const key in parsedValue) {
            if (parsedValue.hasOwnProperty(key)) {
              helperMetadataArray.push({
                key: key,
                value: parsedValue[key],
              })
            }
          }

          appFormattedObj[key] = helperMetadataArray
        } catch (error) {
          console.warn(error)
        }
      }
    }

    setAppMetadataObject(appFormattedObj)
  }, [data])

  const updateAdMetadataMutation = useMutation<string, Error, UpdateAdMetadataRequest>(
    (body: UpdateAdMetadataRequest) => {
      return API.radioAds.updateMetadata(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const onAddClick = () => {
    setAppMetadataArray([...appMetadataArray, { key: '', value: '' }])
  }

  const onDeleteClick = (key: any) => {
    setAppMetadataArray((metadataArray: any) =>
      metadataArray.filter((_item: { [key: string]: string | number }, index: number) => index !== key),
    )
  }

  const handleFieldInputUpdate = (type: string, e: any, index: number) => {
    const newValue = e.target.value

    setAppMetadataArray((prevArray: any) =>
      prevArray.map((item: any, i: number) => (i === index ? { ...item, [type]: newValue } : item)),
    )
  }

  useEffect(() => {
    setAppMetadataObject((prevState: any) => ({
      ...prevState,
      [applicationId]: appMetadataArray,
    }))
  }, [appMetadataArray])

  const handleApplicationChange = (platformOption: string) => {
    setAppMetadataArray([])

    setApplicationId(platformOption)
  }

  useEffect(() => {
    if (appMetadataObject.hasOwnProperty(applicationId)) {
      setAppMetadataArray([...appMetadataObject[applicationId]])
    }
  }, [applicationId])

  const hasEmptyKey = async (obj: any) => {
    for (const arrayKey in obj) {
      if (obj.hasOwnProperty(arrayKey)) {
        const arrayOfObjects = obj[arrayKey]

        const hasEmpty = arrayOfObjects.some((item: any) => item.key === '')

        if (hasEmpty) {
          setShowErrorOnSave(true)

          return true
        }
      }
    }

    setShowErrorOnSave(false)

    return false
  }

  const handleSubmit = async () => {
    let jsonForSave: { [key: string]: any } = {}

    const showError = await hasEmptyKey(appMetadataObject)

    if (showError) {
      return
    }

    if (Object.keys(appMetadataObject).length === 0) {
      jsonForSave = {}
    } else {
      for (const appIdValue in appMetadataObject) {
        if (appIdValue && appMetadataObject[appIdValue].length) {
          const formattedValues = appMetadataObject[appIdValue].reduce((acc: any, obj: any) => {
            acc[obj.key] = obj.value

            return acc
          }, {})

          jsonForSave[appIdValue] = JSON.stringify(formattedValues)
        }
      }
    }

    if (dataType === FileType.AD) {
      const body = {
        adId: dataTypeId,
        metadata: jsonForSave,
      }

      updateAdMetadataMutation.mutate(body)
    }
  }

  return (
    <React.Fragment>
      {<div className={styles.backdropDiv} />}
      <Modal hideBackdrop open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className={styles.container}>
          <div className={styles.header}>{Text.jsonDataModal.enterMetadata}</div>
          <div className={styles.body}>
            <Select
              fullWidth
              data-test="application-field"
              label={'Applications'}
              value={applicationId}
              options={applicationOptions}
              className={styles.select}
              onChange={handleApplicationChange}
            />
            {applicationId === '' ? (
              <div className={styles.noDataWrapper}>Select application from the list</div>
            ) : (
              <JsonDataModalRows
                metadataArray={appMetadataArray}
                handleInputUpdate={handleFieldInputUpdate}
                onDeleteClick={onDeleteClick}
                onAddClick={onAddClick}
              />
            )}
          </div>
          <div className={styles.submitContainer}>
            {showErrorOnSave && (
              <div className={styles.errorInfoBox}>
                <p>{Text.jsonDataModal.errorTextKeyIsMissing}</p>
              </div>
            )}
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
            <Button onClick={handleSubmit}>{Text.common.save}</Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default JsonDataModalWithApplication
