import { useState } from 'react'

import DroppableContainer from './DroppableContainer/DroppableContainer'

import cx from 'classnames'
import styles from './dndTable.module.scss'

interface DndTableProps {
  columns: any[]
  data: any[]
  updateOrder: (updatedOrder: any) => void
  hideUnordered?: boolean
  className?: string
}

const DndTable = ({ columns, data, updateOrder, hideUnordered, className = '' }: DndTableProps) => {
  const containerStyles = cx(styles.container, className)
  const [activeItem, setActiveItem] = useState<null | number>(null)

  const onDrop = (name: string, position: number) => {
    if (activeItem == null || activeItem === undefined) {
      return
    }

    const itemToMove = data[activeItem]

    itemToMove.splice(itemToMove.length - 2, 1, name)

    const updatedItems = data.filter((_, index) => index !== activeItem)

    updatedItems.splice(position, 0, [...itemToMove])

    updateOrder(updatedItems)
  }

  return (
    <div className={containerStyles}>
      <DroppableContainer columns={columns} items={data} name="ordered" setActiveItem={setActiveItem} onDrop={onDrop} />
      {!hideUnordered && (
        <DroppableContainer
          columns={columns}
          items={data}
          name="unordered"
          setActiveItem={setActiveItem}
          onDrop={onDrop}
        />
      )}
    </div>
  )
}

export default DndTable
