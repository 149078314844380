import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import StyngsLsrTable from './../../components/StyngsLsrTable/StyngsLsrTable'
import StyngsRoyaltyFreeTable from './../../components/StyngsRoyaltyFreeTable/StyngsRoyaltyFreeTable'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Tab } from '@mui/material'
import { StyngPageTabs } from 'common/constants'

import styles from './StyngsPage.module.scss'

const StyngsPage = () => {
  const [tabValue, setTabValue] = useState(StyngPageTabs.LSR_TAB)

  useEffect(() => {
    const currentTab = localStorage.getItem('setTab')

    if (currentTab !== null) {
      setTabValue(currentTab as StyngPageTabs)
      localStorage.removeItem('setTab')
    }
  }, [])

  const handleTabChange = (_: React.SyntheticEvent, newValue: StyngPageTabs) => {
    setTabValue(newValue)
  }

  return (
    <TabContext value={tabValue}>
      <Box className={styles.tabs}>
        <TabList centered variant="fullWidth" aria-label="Playlist page tabs" onChange={handleTabChange}>
          <Tab label="LSR " value={StyngPageTabs.LSR_TAB} />
          <Tab label="Royalty Free" value={StyngPageTabs.RF_TAB} />
        </TabList>
      </Box>
      <TabPanel sx={{ padding: 0 }} value={StyngPageTabs.LSR_TAB}>
        <div>
          <StyngsLsrTable />
        </div>
      </TabPanel>
      <TabPanel sx={{ padding: 0 }} value={StyngPageTabs.RF_TAB}>
        <div>
          <StyngsRoyaltyFreeTable />
        </div>
      </TabPanel>
    </TabContext>
  )
}

export default thread(StyngsPage, [observer])
