import React from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useParams } from 'react-router-dom'
import FormStore from 'lib/form/FormStore'

import { usePlaylistBasicInfoQuery } from '../PlaylistsStore'
import LoadingRemote from '../../common/LoadingRemote'

import EditPlaylist from './EditPlaylist/EditPlaylist'

interface ParamProps {
  playlistId: string
}

const PlaylistsPage = () => {
  const formStore = new FormStore()
  const { playlistId } = useParams<ParamProps>()

  const {
    data: playlistBasicInfoData,
    status: playlistBasicInfoStatus,
    refetch: playlistBasicInfoRefetch,
  } = usePlaylistBasicInfoQuery(playlistId)

  return (
    <LoadingRemote status={playlistBasicInfoStatus}>
      <EditPlaylist
        formStore={formStore}
        playlistId={playlistId}
        playlistBasicInfoData={playlistBasicInfoData}
        playlistBasicInfoStatus={playlistBasicInfoStatus}
        playlistBasicInfoRefetch={playlistBasicInfoRefetch}
      />
    </LoadingRemote>
  )
}

export default thread(PlaylistsPage, [observer])
