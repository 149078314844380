/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Duration } from 'luxon'

import useStores from 'common/hook/useStore'

import { Select, MenuItem, Paper, Box } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import API from 'common/api'
import { RoyaltyFreePlaylistTrack } from 'common/api/playlist/playlist'
import { ApiStatuses, FileType, categoriesTrackOptions } from 'common/constants'
import { formatItemsForCsvData, isMetadataSetForCurrentPlatform } from 'common/utils'
import { useRoyaltyFreeTracksInPlaylistQuery } from '../../pages/EditPlaylist/TracksInPlaylistStore'

import Table from 'ui/Table/Table'
import Button from 'ui/Button/Button'
import CsvModal from 'ui/CsvModal/CsvModal'
import DeleteModal from 'ui/DeleteModal/DeleteModal'
import JsonDataModalWithPlatform from 'ui/JsonDataModal/JsonDataModalWithPlatform/JsonDataModalWithPlatform'
import LoadingComponent from 'ui/Loading/LoadingPage'

import Text from 'locale/strings'

import styles from './royaltyFreePlaylistTable.module.scss'
interface PlaylistProps {
  playlistId: string
  platformId?: string
}

const RoyaltyFreePlaylistTable = ({ playlistId, platformId }: PlaylistProps) => {
  const { notificationsStore } = useStores()
  const [playlistTracksForCsv, setPlaylistTracksForCsv] = useState<RoyaltyFreePlaylistTrack[]>([])
  const [trackId, setTrackId] = useState<string>('')
  const [selectedTrack, setSelectedTrack] = useState<RoyaltyFreePlaylistTrack | null>(null)
  const [openCsvModal, setOpenCsvModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  const { data, status: royaltyFreeTracksStatus, refetch } = useRoyaltyFreeTracksInPlaylistQuery(playlistId)
  const royaltyFreeTracks = data?.tracks ?? []

  const changeRoyaltyFreeSeedTrack = useMutation(
    (body: any) => {
      return API.playlist.setSeedTrack(body)
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('The list of selected categories has been successfully modified')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  useEffect(() => {
    if (royaltyFreeTracksStatus === ApiStatuses.SUCCESS) {
      setPlaylistTracksForCsv([...royaltyFreeTracks].reverse())
    }
  }, [royaltyFreeTracksStatus])

  const handleChangeRoyaltyFreeTrackCategory = (event: SelectChangeEvent<unknown>, track: RoyaltyFreePlaylistTrack) => {
    const selectedCategories = event.target.value

    changeRoyaltyFreeSeedTrack.mutate({
      playlistId: playlistId,
      categories: selectedCategories,
      trackId: track.id,
    })
  }

  const columns = [
    'Id',
    'Name',
    'Album',
    'Artist Names',
    'Duration',
    'File ID',
    'External Metadata',
    'Track category',
    'Actions',
  ]

  const dataTable = royaltyFreeTracks.map((track: RoyaltyFreePlaylistTrack) => [
    track.id,
    track.name,
    track.album,
    track.artist,
    Duration.fromISO(track.duration).toFormat('mm:ss'),
    track.fileId,
    isMetadataSetForCurrentPlatform(track.externalMetadata, platformId),
    <Select
      fullWidth
      multiple
      className={styles.customSelect}
      displayEmpty={true}
      renderValue={(value) => (value?.length ? (Array.isArray(value) ? value.join(', ') : value) : 'NO')}
      key={track.id}
      value={track.categories}
      onChange={(e) => handleChangeRoyaltyFreeTrackCategory(e, track)}
    >
      {categoriesTrackOptions.map((category) => (
        <MenuItem key={category.value} value={category.value}>
          {category.label}
        </MenuItem>
      ))}
    </Select>,
  ])

  const csvColumns = ['Track Name', 'Album Name', 'Artists', 'Duration', 'File ID', 'Metadata']

  const csvData = playlistTracksForCsv.map((track: RoyaltyFreePlaylistTrack) => [
    formatItemsForCsvData(track.name),
    formatItemsForCsvData(track.album),
    formatItemsForCsvData(track.artist),
    Duration.fromISO(track.duration).as('seconds'),
    formatItemsForCsvData(track.fileId),
    Object.keys(track.externalMetadata).length === 0 ? 'NULL' : track.externalMetadata,
  ])

  const handleCloseCsvModal = () => {
    setOpenCsvModal(false)
  }

  const handleOpenCsvModal = () => {
    setOpenCsvModal(true)
  }

  const handleDeleteClick = (id: string) => {
    setTrackId(id)
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setTrackId('')
    setShowDeleteModal(false)
  }

  const handleCloseJsonModal = () => {
    setSelectedTrack(null)
    setJsonDataModal(false)
  }

  const handleOpenJsonModal = (id: string) => {
    const track = royaltyFreeTracks.filter((track) => track.id === id)

    setSelectedTrack(track[0])
    setJsonDataModal(true)
  }

  const onDeleteMutation = useMutation(
    () => {
      return API.playlist.deleteTrackFromPlaylist({ playlistId: playlistId, trackId: trackId })
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('Track successfully deleted from playlist')
        setShowDeleteModal(false)
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)

        setShowDeleteModal(false)
      },
    },
  )

  const handleDeleteTrack = () => {
    onDeleteMutation.mutate()
  }

  const renderPlaylistTracks = () => {
    if (royaltyFreeTracks.length > 0) {
      return (
        <React.Fragment>
          <Paper elevation={3}>
            <Table
              hideEdit
              jsonData
              stickyHeader
              hiddenColumns={['External Metadata']}
              size="medium"
              sx={{
                tableLayout: 'fixed',
              }}
              columns={columns}
              data={dataTable}
              onDeleteClick={handleDeleteClick}
              onJsonDataClick={handleOpenJsonModal}
            />
          </Paper>
          <div className={styles.buttonsHolder}>
            <Button onClick={handleOpenCsvModal}>{Text.common.download}</Button>
          </div>
        </React.Fragment>
      )
    }

    return <p className={styles.emptyResponse}>There are no tracks added to this playlist</p>
  }

  return (
    <div className={styles.playlistEdit}>
      <Choose>
        <When condition={royaltyFreeTracksStatus === ApiStatuses.LOADING}>
          <LoadingComponent />
        </When>
        <When condition={royaltyFreeTracksStatus === ApiStatuses.SUCCESS}>
          <Box className={styles.sectionWrapper}>
            <div>{renderPlaylistTracks()}</div>

            {openCsvModal && (
              <CsvModal
                open={openCsvModal}
                dataTable={csvData}
                columns={csvColumns}
                handleClose={handleCloseCsvModal}
              />
            )}

            {showDeleteModal && (
              <DeleteModal
                open={showDeleteModal}
                headerContent={'Remove song from playlist'}
                handleClose={handleCloseDeleteModal}
                text={'Are you sure you want to remove this song from playlist ?'}
                handleSubmit={handleDeleteTrack}
              />
            )}

            {jsonDataModal && (
              <JsonDataModalWithPlatform
                selectedPlatformId={platformId ?? ''}
                dataTypeId={selectedTrack?.id}
                data={selectedTrack?.externalMetadata}
                dataType={FileType.ROYALTY_FREE_TRACK}
                open={jsonDataModal}
                refetch={refetch}
                handleClose={handleCloseJsonModal}
              />
            )}
          </Box>
        </When>
        <When condition={royaltyFreeTracksStatus === ApiStatuses.ERROR}>
          <div>{Text.errors.generalErrorMessage}</div>
        </When>
      </Choose>
    </div>
  )
}

export default RoyaltyFreePlaylistTable
