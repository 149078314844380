import * as R from 'ramda'
import { Company } from './api/company/company'
import { Application } from './api/app/app'
import { ReportType } from './api/reporting/reports'
import { StyngSourceType } from './api/styng/styng'

import { OptionType } from 'common/api/common/common'

export const BASE_PATH = '/'
export const API_VERSION = '1'
export const INITIAL_PAGE = 1
export const TEXT_TRUNCATE_SIZE = 40
export const ALL_RECORDS = 1000
export const MAX_SIZE_IN_BYTES = 8192
export const MAX_SIZE_IN_KB = 8

export enum StoreState {
  PENDING = 0,
  DONE = 1,
  ERROR = 2,
  INACTIVE = 3,
}

export enum KEYBOARD_KEY {
  ENTER = 'Enter',
}

export enum Permission {
  USERS_VIEW = 'USERS_VIEW',
  PLAYLISTS_VIEW = 'PLAYLISTS_VIEW',
  COMPANIES_VIEW = 'COMPANIES_VIEW',
  CREATE_STYNG = 'CREATE_STYNG',
  CREATE_STYNGLIST = 'CREATE_STYNGLIST',
  APP_VIEW = 'APP_VIEW',
  CREATE_APPS = 'CREATE_APPS',
  APP_EVENTS_VIEW = 'APP_EVENTS_VIEW',
  CREATE_APP_EVENT = 'CREATE_APP_EVENT',
  CREATE_APP = 'CREATE_APP',
  EDIT_APP = 'EDIT_APP',
  EDIT_COMPANY = 'EDIT_COMPANY',
  PAGE_VIEW = 'PAGE_VIEW',
  EDIT_APP_STYNGLIST = 'EDIT_APP_STYNGLIST',
}

export enum ApiStatuses {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  IDLE = 'idle',
}

export enum FileType {
  TRACK = 'TRACK',
  PLAYLIST = 'PLAYLIST',
  ROYALTY_FREE_TRACK = 'ROYALTY_FREE_TRACK',
  STYNG = 'STYNG',
  AD = 'AD',
}

export enum FilterFields {
  ID = 'ID',
  NAME = 'NAME',
  APPLICATION = 'APPLICATION',
  AGE_RESTRICTION = 'AGE_RESTRICTION',
  AVAILABILITY = 'AVAILABILITY',
  PUBLISHED = 'PUBLISHED',
  TYPE = 'TYPE',
  ISRC = 'ISRC',
  ARTIST = 'ARTIST',
  SONG_NAME = 'SONG_NAME',
  GENRE = 'GENRE',
  PLAYLIST = 'PLAYLIST',
  PLATFORM = 'PLATFORM',
  PREFIX = 'PREFIX',
  STATUS = 'STATUS',
  SOURCE = 'SOURCE',
}

export enum StoreNames {
  COMPANIES_STORE = 'COMPANIES_STORE',
  PLAYLISTS_STORE = 'PLAYLISTS_STORE',
  STYNGLISTS_STORE = 'STYNGLISTS_STORE',
  REPORTING_STORE = 'REPORTING_STORE',
}

export enum EditPlaylistTabs {
  GENERAL_INFORMATION_TAB = 'GENERAL_INFORMATION_TAB',
  PLAYLIST_TRACKS_TAB = 'PLAYLIST_TRACKS_TAB',
  ADS_MANAGEMENT = 'ADS_MANAGEMENT',
}

export enum PlaylistTiers {
  PREMIUM = 'PREMIUM',
}

export enum EditSoundboardTabs {
  GENERAL_INFORMATION_TAB = 'GENERAL_INFORMATION_TAB',
  CATEGORIES_TAB = 'CATEGORIES_TAB',
  APPS_TAB = 'APPS_TAB',
}

export enum StyngPageTabs {
  LSR_TAB = 'LSR',
  RF_TAB = 'ROYALTY_FREE',
}

export interface ChildrenProps {
  children: React.ReactElement[] | React.ReactElement
}

export const defaultImg = '/default-image.png'

export enum AdminMode {
  ON = 'ON',
  OFF = 'OFF',
}

export enum PlaylistTypes {
  LICENSED = 'LICENSED',
  ROYALTY_FREE = 'ROYALTY_FREE',
}

export enum TimePoints {
  START = 'START',
  END = 'END',
}

export interface IntegrationType {
  id: string
  name: string
}

export interface ProductType {
  id: string
  name: string
  description: string
}

export interface StyngType {
  description: string
  type: string
}

export const toCompanyOptions = (item: Company) => ({
  label: item.name,
  value: item.id,
})

export const toIntegrationTypeOptions = (item: IntegrationType) => ({
  label: item.name,
  value: item.id,
})

export const toProductTypeOptions = (item: ProductType) => ({
  label: item.name,
  value: item.id,
  description: item.description,
})

export const toAppOptions = (item: Application): OptionType => ({
  label: item.name,
  value: item.id,
})

export const toStyngTypeOptions = (item: StyngType) => ({
  label: item.description,
  value: item.type,
})

export const toReportsTypeOptions = (item: ReportType) => ({
  label: item.name,
  value: item.type,
})

export const toSourceTypeOptions = (item: StyngSourceType): OptionType => ({
  label: item.description,
  value: item.type,
})

export const categoriesTrackOptions: OptionType[] = [
  {
    label: 'Primary',
    value: 'PRIMARY',
  },
  {
    label: 'Secondary',
    value: 'SECONDARY',
  },
  {
    label: 'Tertiary',
    value: 'TERTIARY',
  },
]

export const mapIndexed = R.addIndex(R.map)
